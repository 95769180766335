<template>
  <div class="KpiSetting_wrapper">
    <el-card class="box_card">
      <div slot="header" class="clearfix">
        <span>奖励豆设置</span>
      </div>
      <div class="kpi_info_wrap">
        <div class="kpi_info_item">
          <p class="name">浏览</p>
          <el-input
            v-model.float="KpiDetail.viewBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">裂变</p>
          <el-input
            v-model.float="KpiDetail.fissionBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">报名</p>
          <el-input
            v-model.float="KpiDetail.enrollmentBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">售券</p>
          <el-input
            v-model.float="KpiDetail.cardBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">秒杀</p>
          <el-input
            v-model.float="KpiDetail.seckillUserBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">集赞</p>
          <el-input
            v-model.float="KpiDetail.thumbBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">进入直播间</p>
          <el-input
            v-model.float="KpiDetail.liveViewBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播下订</p>
          <el-input
            v-model.float="KpiDetail.liveOrderCusBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">过程自签单</p>
          <el-input
            v-model.float="KpiDetail.processSelfOrderBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">过程贡献单</p>
          <el-input
            v-model.float="KpiDetail.processDevoteOrderBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播自签单</p>
          <el-input
            v-model.float="KpiDetail.liveSelfOrderBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播贡献单</p>
          <el-input
            v-model.float="KpiDetail.liveOtherOrderBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播自签转单</p>
          <el-input
            v-model.float="KpiDetail.liveSelfOrderChangeBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播贡献转单</p>
          <el-input
            v-model.float="KpiDetail.liveOtherOrderChangeBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播预约</p>
          <el-input
            v-model.float="KpiDetail.appointmentBean"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
      </div>
    </el-card>
    <el-card class="box_card">
      <div slot="header" class="clearfix">
        <span>奖励金设置</span>
      </div>
      <div class="kpi_info_wrap">
        <div class="kpi_info_item">
          <p class="name">奖励金上限</p>
          <el-input
            v-model.float="KpiDetail.maxBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
      </div>
      <div class="kpi_info_wrap">
        <div class="kpi_info_item">
          <p class="name">浏览</p>
          <el-input
            v-model.float="KpiDetail.viewBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">裂变</p>
          <el-input
            v-model.float="KpiDetail.fissionBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">报名</p>
          <el-input
            v-model.float="KpiDetail.enrollmentBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">售券</p>
          <el-input
            v-model.float="KpiDetail.cardBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">秒杀</p>
          <el-input
            v-model.float="KpiDetail.seckillUserBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">集赞</p>
          <el-input
            v-model.float="KpiDetail.thumbBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">进入直播间</p>
          <el-input
            v-model.float="KpiDetail.liveViewBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播下订</p>
          <el-input
            v-model.float="KpiDetail.liveOrderCusBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">过程自签单</p>
          <el-input
            v-model.float="KpiDetail.processSelfOrderBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">过程贡献单</p>
          <el-input
            v-model.float="KpiDetail.processDevoteOrderBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播自签单</p>
          <el-input
            v-model.float="KpiDetail.liveSelfOrderBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播贡献单</p>
          <el-input
            v-model.float="KpiDetail.liveOtherOrderBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播自签转单</p>
          <el-input
            v-model.float="KpiDetail.liveSelfOrderChangeBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播贡献转单</p>
          <el-input
            v-model.float="KpiDetail.liveOtherOrderChangeBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播预约</p>
          <el-input
            v-model.float="KpiDetail.appointmentBonus"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
      </div>
    </el-card>
    <el-card class="box_card">
      <div slot="header" class="clearfix">
        <span>积分设置</span>
      </div>
      <div class="kpi_info_wrap">
        <div class="kpi_info_item">
          <p class="name">浏览</p>
          <el-input
            v-model.float="KpiDetail.viewScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">裂变</p>
          <el-input
            v-model.float="KpiDetail.fissionScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">报名</p>
          <el-input
            v-model.float="KpiDetail.enrollmentScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">售券</p>
          <el-input
            v-model.float="KpiDetail.cardScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">秒杀</p>
          <el-input
            v-model.float="KpiDetail.seckillUserScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">集赞</p>
          <el-input
            v-model.float="KpiDetail.thumbScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">进入直播间</p>
          <el-input
            v-model.float="KpiDetail.liveViewScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播下订</p>
          <el-input
            v-model.float="KpiDetail.liveOrderCusScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">过程自签单</p>
          <el-input
            v-model.float="KpiDetail.processSelfOrderScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">过程贡献单</p>
          <el-input
            v-model.float="KpiDetail.processDevoteOrderScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播自签单</p>
          <el-input
            v-model.float="KpiDetail.liveSelfOrderScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播贡献单</p>
          <el-input
            v-model.float="KpiDetail.liveOtherOrderScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播自签转单</p>
          <el-input
            v-model.float="KpiDetail.liveSelfOrderChangeScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播贡献转单</p>
          <el-input
            v-model.float="KpiDetail.liveOtherOrderChangeScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
        <div class="kpi_info_item">
          <p class="name">直播预约</p>
          <el-input
            v-model.float="KpiDetail.appointmentScore"
            maxlength="8"

            placeholder="请输入数值"
          ></el-input>
        </div>
      </div>
    </el-card>
    <el-card class="box_card">
      <div slot="header" class="clearfix">
        <span>奖励政策</span>
      </div>
      <div>
        <vue-ueditor-wrap v-model="KpiDetail.rewardRule" :config="ueditorConfig"></vue-ueditor-wrap>
      </div>
      <el-button class="mt10" type="primary" @click="submitInfo">保存</el-button>
    </el-card>
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
import VueUeditorWrap from "vue-ueditor-wrap";

let origin = window.location.origin;
let ueditorurl = "";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("192.168.")||
  window.location.hostname.includes("admin-test.youxiangzb.com")
) {
  ueditorurl = "/UEditor/";
} else {
  // ueditorurl = "https://admin.youxiangzb.com/admin/open/UEditor/";
  ueditorurl = "/UEditor/";
}

export default {
  name: "KpiSetting", // 导购员设置-kpi设置
  components: {
    VueUeditorWrap,
  },
  props: {
    activityID: String,
  },
  data() {
    return {
      input: "",
      ueditorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        autoFloatEnabled: false,
        initialFrameWidth: 700,
        initialFrameHeight: 320,
        // UEDITOR_HOME_URL: `${process.env.VUE_APP_URL}/js/ueditor1_4_3_3-utf8-net/utf8-net/`
        UEDITOR_HOME_URL: ueditorurl,
      },
      KpiDetail: {
        actId: this.activityID,
        cardBean: 0,
        cardBonus: 0,
        cardScore: 0,
        enrollmentBean: 0,
        enrollmentBonus: 0,
        enrollmentScore: 0,
        fissionBean: 0,
        fissionBonus: 0,
        fissionScore: 0,
        liveOrderCusBean: 0,
        liveOrderCusBonus: 0,
        liveOrderCusScore: 0,
        liveOtherOrderBean: 0,
        liveOtherOrderBonus: 0,
        liveOtherOrderChangeBean: 0,
        liveOtherOrderChangeBonus: 0,
        liveOtherOrderChangeScore: 0,
        liveOtherOrderScore: 0,
        liveSelfOrderBean: 0,
        liveSelfOrderBonus: 0,
        liveSelfOrderChangeBean: 0,
        liveSelfOrderChangeBonus: 0,
        liveSelfOrderChangeScore: 0,
        liveSelfOrderScore: 0,
        liveViewBean: 0,
        liveViewBonus: 0,
        liveViewScore: 0,
        maxBonus: 0,
        processDevoteOrderBean: 0,
        processDevoteOrderBonus: 0,
        processDevoteOrderScore: 0,
        processSelfOrderBean: 0,
        processSelfOrderBonus: 0,
        processSelfOrderScore: 0,
        rewardRule: '',
        seckillUserBean: 0,
        seckillUserBonus: 0,
        seckillUserScore: 0,
        thumbBean: 0,
        thumbScore: 0,
        thumbBonus: 0,
        viewBean: 0,
        viewBonus: 0,
        viewScore: 0,
        appointmentScore: 0,
        appointmentBonus: 0,
        appointmentBean: 0,
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取Kpi设置详情
    async getQueryByActId() {
      try {
        const data = await GuideAjax.getQueryByActId(this.activityID);
        if (data.data) {
          this.KpiDetail = data.data;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 确认编辑Kpi配置信息
    // 需添加填写限制数字，最大10位。富文本添加
    async submitInfo() {
      // 是否需要判断新增和编辑（创建和编辑时间是否需要更改）
      try {
        // for (var key in this.KpiDetail) {
        //   var item = this.KpiDetail[key];
        //   // 判断输入售价
        //   if (!/(^[1-9]\d*$)/.test(item)) {
        //     this.$message.warning("输入数值需为正整数");
        //     return;
        //   }
        // }

        let act = JSON.parse(JSON.stringify(this.KpiDetail));
        await GuideAjax.getSaveOrUpdate(act);
        this.$message.success("保存成功");
        await this.getQueryByActId();
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryByActId();
  },
  mounted() {},
};
</script>

<style lang="scss">
.KpiSetting_wrapper {
  .box_card {
    margin-bottom: 20px;
    .el-card__body {
      .kpi_info_wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .kpi_info_item {
          width: 160px;
          line-height: 40px;
          color: #409eff;
          border: 1px solid #d9ecff;
          background-color: #ecf5ff;
          padding: 0 20px 10px;
          margin: 0 10px 10px 0;
          cursor: pointer;
          border-radius: 4px;
          &:hover {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "KpiSetting_wrapper" },
    [
      _c("el-card", { staticClass: "box_card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header"
          },
          [_c("span", [_vm._v("奖励豆设置")])]
        ),
        _c("div", { staticClass: "kpi_info_wrap" }, [
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("浏览")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.viewBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "viewBean", $$v)
                  },
                  expression: "KpiDetail.viewBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("裂变")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.fissionBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "fissionBean", $$v)
                  },
                  expression: "KpiDetail.fissionBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("报名")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.enrollmentBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "enrollmentBean", $$v)
                  },
                  expression: "KpiDetail.enrollmentBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("售券")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.cardBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "cardBean", $$v)
                  },
                  expression: "KpiDetail.cardBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("秒杀")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.seckillUserBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "seckillUserBean", $$v)
                  },
                  expression: "KpiDetail.seckillUserBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("集赞")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.thumbBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "thumbBean", $$v)
                  },
                  expression: "KpiDetail.thumbBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("进入直播间")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveViewBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveViewBean", $$v)
                  },
                  expression: "KpiDetail.liveViewBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播下订")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOrderCusBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOrderCusBean", $$v)
                  },
                  expression: "KpiDetail.liveOrderCusBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("过程自签单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.processSelfOrderBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "processSelfOrderBean", $$v)
                  },
                  expression: "KpiDetail.processSelfOrderBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("过程贡献单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.processDevoteOrderBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "processDevoteOrderBean", $$v)
                  },
                  expression: "KpiDetail.processDevoteOrderBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播自签单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveSelfOrderBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveSelfOrderBean", $$v)
                  },
                  expression: "KpiDetail.liveSelfOrderBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播贡献单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOtherOrderBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOtherOrderBean", $$v)
                  },
                  expression: "KpiDetail.liveOtherOrderBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播自签转单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveSelfOrderChangeBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveSelfOrderChangeBean", $$v)
                  },
                  expression: "KpiDetail.liveSelfOrderChangeBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播贡献转单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOtherOrderChangeBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOtherOrderChangeBean", $$v)
                  },
                  expression: "KpiDetail.liveOtherOrderChangeBean"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播预约")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.appointmentBean,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "appointmentBean", $$v)
                  },
                  expression: "KpiDetail.appointmentBean"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("el-card", { staticClass: "box_card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header"
          },
          [_c("span", [_vm._v("奖励金设置")])]
        ),
        _c("div", { staticClass: "kpi_info_wrap" }, [
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("奖励金上限")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.maxBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "maxBonus", $$v)
                  },
                  expression: "KpiDetail.maxBonus"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "kpi_info_wrap" }, [
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("浏览")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.viewBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "viewBonus", $$v)
                  },
                  expression: "KpiDetail.viewBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("裂变")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.fissionBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "fissionBonus", $$v)
                  },
                  expression: "KpiDetail.fissionBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("报名")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.enrollmentBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "enrollmentBonus", $$v)
                  },
                  expression: "KpiDetail.enrollmentBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("售券")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.cardBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "cardBonus", $$v)
                  },
                  expression: "KpiDetail.cardBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("秒杀")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.seckillUserBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "seckillUserBonus", $$v)
                  },
                  expression: "KpiDetail.seckillUserBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("集赞")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.thumbBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "thumbBonus", $$v)
                  },
                  expression: "KpiDetail.thumbBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("进入直播间")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveViewBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveViewBonus", $$v)
                  },
                  expression: "KpiDetail.liveViewBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播下订")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOrderCusBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOrderCusBonus", $$v)
                  },
                  expression: "KpiDetail.liveOrderCusBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("过程自签单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.processSelfOrderBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "processSelfOrderBonus", $$v)
                  },
                  expression: "KpiDetail.processSelfOrderBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("过程贡献单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.processDevoteOrderBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "processDevoteOrderBonus", $$v)
                  },
                  expression: "KpiDetail.processDevoteOrderBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播自签单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveSelfOrderBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveSelfOrderBonus", $$v)
                  },
                  expression: "KpiDetail.liveSelfOrderBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播贡献单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOtherOrderBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOtherOrderBonus", $$v)
                  },
                  expression: "KpiDetail.liveOtherOrderBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播自签转单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveSelfOrderChangeBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveSelfOrderChangeBonus", $$v)
                  },
                  expression: "KpiDetail.liveSelfOrderChangeBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播贡献转单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOtherOrderChangeBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOtherOrderChangeBonus", $$v)
                  },
                  expression: "KpiDetail.liveOtherOrderChangeBonus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播预约")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.appointmentBonus,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "appointmentBonus", $$v)
                  },
                  expression: "KpiDetail.appointmentBonus"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("el-card", { staticClass: "box_card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header"
          },
          [_c("span", [_vm._v("积分设置")])]
        ),
        _c("div", { staticClass: "kpi_info_wrap" }, [
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("浏览")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.viewScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "viewScore", $$v)
                  },
                  expression: "KpiDetail.viewScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("裂变")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.fissionScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "fissionScore", $$v)
                  },
                  expression: "KpiDetail.fissionScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("报名")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.enrollmentScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "enrollmentScore", $$v)
                  },
                  expression: "KpiDetail.enrollmentScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("售券")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.cardScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "cardScore", $$v)
                  },
                  expression: "KpiDetail.cardScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("秒杀")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.seckillUserScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "seckillUserScore", $$v)
                  },
                  expression: "KpiDetail.seckillUserScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("集赞")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.thumbScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "thumbScore", $$v)
                  },
                  expression: "KpiDetail.thumbScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("进入直播间")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveViewScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveViewScore", $$v)
                  },
                  expression: "KpiDetail.liveViewScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播下订")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOrderCusScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOrderCusScore", $$v)
                  },
                  expression: "KpiDetail.liveOrderCusScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("过程自签单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.processSelfOrderScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "processSelfOrderScore", $$v)
                  },
                  expression: "KpiDetail.processSelfOrderScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("过程贡献单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.processDevoteOrderScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "processDevoteOrderScore", $$v)
                  },
                  expression: "KpiDetail.processDevoteOrderScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播自签单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveSelfOrderScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveSelfOrderScore", $$v)
                  },
                  expression: "KpiDetail.liveSelfOrderScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播贡献单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOtherOrderScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOtherOrderScore", $$v)
                  },
                  expression: "KpiDetail.liveOtherOrderScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播自签转单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveSelfOrderChangeScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveSelfOrderChangeScore", $$v)
                  },
                  expression: "KpiDetail.liveSelfOrderChangeScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播贡献转单")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.liveOtherOrderChangeScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "liveOtherOrderChangeScore", $$v)
                  },
                  expression: "KpiDetail.liveOtherOrderChangeScore"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi_info_item" },
            [
              _c("p", { staticClass: "name" }, [_vm._v("直播预约")]),
              _c("el-input", {
                attrs: { maxlength: "8", placeholder: "请输入数值" },
                model: {
                  value: _vm.KpiDetail.appointmentScore,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "appointmentScore", $$v)
                  },
                  expression: "KpiDetail.appointmentScore"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "el-card",
        { staticClass: "box_card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("奖励政策")])]
          ),
          _c(
            "div",
            [
              _c("vue-ueditor-wrap", {
                attrs: { config: _vm.ueditorConfig },
                model: {
                  value: _vm.KpiDetail.rewardRule,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiDetail, "rewardRule", $$v)
                  },
                  expression: "KpiDetail.rewardRule"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "mt10",
              attrs: { type: "primary" },
              on: { click: _vm.submitInfo }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }